<template>
  <div class="container">
    <img class="dylogo" src="@/images/dy/dy.jpg">
    <div class="imbox">
      <!-- 第一排 4 个图标 -->
      <a class="menu" @click="showModal">
        <div class="btimgbox">
          <img class="dybtimg" src="@/images/dy/cy.png">
          <p class="title-subject">人员查验</p>
          <p class="title-subject-sub">服务人员查验</p>
        </div>
      </a>
      <a class="menu" href="./jiesuan.html">
        <div class="btimgbox">
          <img class="dybtimg" src="@/images/dy/js.png">
          <p class="title-subject">资金清算</p>
          <p class="title-subject-sub">资金清算</p>
        </div>
      </a>
      <a class="menu"  target="_blank" @click="queryMeeting">
        <div class="btimgbox">
          <img class="dybtimg" src="@/images/dy/bg-download.png">
          <p class="title-subject">认证会议</p>
          <p class="title-subject-sub" id="huiyi-name">云服务</p>
        </div>
      </a>
      <a href="chatlink.html" class="menu" target="_blank">
        <div class="btimgbox">
          <img class="dybtimg" src="@/images/dy/kf.png">
          <p class="title-subject">在线客服</p>
          <p class="title-subject-sub">投诉与建议</p>
        </div>
      </a>
      <!-- 第二排 1 个图标 -->
      <a class="menu full-width" @click="showPolicyInputModal">
        <div class="btimgbox">
          <img class="dybtimg2" src="@/images/dy/cxbd.png">
          <p class="title-subject">查询保单</p>
          <p class="title-subject-sub">查询保单</p>
        </div>
      </a>
    </div>

    <!-- 人员查验弹窗 -->
    <div v-if="isModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>服务人员验证</h2>
        <div class="modal-body">
          <input type="text" class="input" maxlength="10" placeholder="请输入服务人员工号" v-model="workerId">
          <button class="yzbt" @click="validateWorkerId">立即验证</button>
        </div>
        <div v-if="validationResult && !validationResult.error" class="result">
          <p>尊敬的用户，您输入的工号验证结果：</p>
          <p>姓名：{{ validationResult.name }}</p>
          <p>部门：{{ validationResult.department }}</p>
          <p>工号：{{ validationResult.id }}</p>
          <img v-if="validationResult.image" class="gzzimg" :src="validationResult.image" alt="人员图像">
        </div>
      </div>
    </div>

    <!-- 输入身份证和姓名的弹窗 -->
    <div v-if="isPolicyInputModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="closePolicyInputModal">&times;</span>
        <h2>查询保单</h2>
        <div class="modal-body">
          <input type="text" class="input" placeholder="请输入姓名" maxlength="5" v-model="policyHolderName">
          <input type="text" class="input" placeholder="请输入身份证后6位查询" maxlength="6" v-model="policyHolderId">
          <button class="yzbt" @click="queryPolicy">查询保单</button>
        </div>
      </div>
    </div>

    <!-- 查询保单弹窗 -->
    <div v-if="isPolicyModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="closePolicyModal">&times;</span>
        <h2 class="titlewz">保单信息</h2>
        <div class="policy-info">
          <div class="doc-title data_edu"><img class="bdimg" src="@/images/dy/dylogo.jpg">中国人保百万保障保险单（电子保单）</div>
          <div class="doc-info">
            <div class="info bold">
              <div class="title">投保人：</div>
              <div class="content red data_edu" style="color:red">{{ policyHolderName }}</div>
            </div>
            <div class="info bold">
              <div class="title">身份证后6位：</div>
              <div class="content red data_edu" style="color:red">{{ policyHolderId }}</div>
            </div>
            <div class="info">
              <div class="title">保单流水号：</div>
              <div class="content red data_edu" style="color:red">{{ policySerialNumber }}</div>
            </div>
            <div class="info">
              <div class="title">保险单号：</div>
              <div class="content red data_edu" style="color:red">{{ insuranceNumber }}</div>
            </div>
          </div>
          <div class="doc-yq" style="color:red">该保单本次使用免费，使用后今晚将自动续费。续费如下</div>
          <div class="doc-content">
            <div>本保险合同由保险条款、投保单、保险单或其他保险凭证以及批单组成。</div>
            <span>保险额度： <span class="data_edu">100万</span></span> <br>
            <span>保障费用： <span class="data_efee">2000元/月  24000元/年</span></span><br>
            <span>保障范围： <span class="data_fanwei">个人支付账户（银行卡、零钱、零钱通、理财通、银行储蓄、分付等）</span></span><br>
            <span>保障查询：  <span class="data_chaxunu">提供第三方支付平台办理业务查询保险业务（微信，支付宝，抖音，京东等）</span></span><br>
            <span>保障说明： <span class="data_desc">个人支付账户因被他人盗用而导致的资金损失，按损失金额承诺赔付，不限赔付次数，每年累计赔付金额最高100万。</span></span><br>
            <span style="color:red">重要提示:<span class="data_tishi">百万保障保险业务如没在规定时间内关闭，会从您名下银联账户扣除保险费用，保险费用是28800三年扣满，扣满三年后 您就成为了百万保障的一名保险用户</span></span><br>
            <span style="color:red">扣费说明： <span class="data_tishi">若您同意继续使用银联中心按照商户扣费指令有权从您名下银联账户逐一扣费（储蓄卡，信用卡）以及您的第三方金融账户（支付宝，微信，贷款账户）且后续无需在验证您的支付密码，短信动态码等信息</span></span> <br>
          </div>
          <div style="margin-top: 5px;">签发机构：<span class="data_jigou data_edu">中国人民财产保险股份有限公司</span> 邮政编码：<span id="data_youbian">550081</span></div>
          <div>协办监管：<span class="data_jianguan data_edu">腾讯微保（微民保险代理有限公司）</span></div>
          <div>制单：<span  class="data_zhidan data_edu" style="color:red">吴晓晗</span></div>
          <br>
          <div class="r">
            <div class="image-wrapper">
                <div class="text-overlay">
                    <p></p>
                    <div class="r data_edu">企业信用税号：91100000710931483R</div>
                    <div class="r data_jigou data_edu">中国人民保险集团股份有限公司</div>
                    <div class="date-container">
                        <img src="@/images/dy/dygz.png" class="seal">
                        <div class="r date data_edu">核验日期：{{ currentDate }}</div>
                    </div>
                    <p></p>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>

    <img class="bottomimg" src="@/images/dy/dybottomimg.jpg" alt="">

  </div>
</template>

<script>
import { showToast } from 'vant';

export default {
  data() {
    return {
      isModalVisible: false,
      isPolicyInputModalVisible: false,
      isPolicyModalVisible: false,
      workerId: '',
      policyHolderName: '',
      policyHolderId: '',
      policySerialNumber: 'BD6351228',
      insuranceNumber: 'PIC56183213',
      validationResult: null,
      currentDate: new Date().toLocaleDateString(),
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.isPolicyInputModalVisible = false; // 关闭所有弹窗
      this.isPolicyModalVisible = false; // 关闭所有弹窗
      this.workerId = ''; // 清空输入框
      this.validationResult = null; // 清空验证结果
    },
    showPolicyInputModal() {
      this.isPolicyInputModalVisible = true;
    },
    closePolicyInputModal() {
      this.isPolicyInputModalVisible = false;
      this.isModalVisible = false; // 关闭所有弹窗
      this.isPolicyModalVisible = false; // 关闭所有弹窗
      this.policyHolderName = '';
      this.policyHolderId = '';
    },
    closePolicyModal() {
      this.isPolicyModalVisible = false;
      this.isModalVisible = false; // 关闭所有弹窗
      this.isPolicyInputModalVisible = false; // 关闭所有弹窗
    },
    validateWorkerId() {
      const mockDatabase = {
        '110507': { name: '张凯', department: '客服部', id: '110507', image: require('@/images/piccimg/zk.jpg') },
        '113537': { name: '王艳', department: '客服部', id: '113537', image: require('@/images/piccimg/wy.jpg') },
        '19070': { name: '张兆强', department: '个人账户管理中心', id: '19070', image: require('@/images/piccimg/zzq.jpg') },
        '10297': { name: '张鑫妮', department: '客户服务部', id: 'K10297', image: require('@/images/piccimg/zxn.jpg') },
        '0085': { name: '林美慧', department: '客户服务部', id: '0085', image: require('@/images/piccimg/lmh.jpg') },
        '101148': { name: '陈正西', department: '客户服务部', id: 'W101148', image: require('@/images/piccimg/czx.jpg') },
        '101124': { name: '孙丽', department: '客户服务部', id: 'W101124', image: require('@/images/piccimg/sl.jpg') },
        '101156': { name: '陈正西', department: '中国银联服务中心', id: 'W101148', image: require('@/images/piccimg/czx2.jpg') },
        '101166': { name: '张丽', department: '中国银联服务中心', id: '101166', image: require('@/images/piccimg/czx2.jpg') },
      };

      if (mockDatabase[this.workerId]) {
        this.validationResult = mockDatabase[this.workerId];
      } else {
        this.validationResult = { error: true, message: '未找到此工号的相关信息' };
        showToast({ message: '工号不存在', type: 'fail' });
      }
    },
    // 查询保单方法
    async queryPolicy() {
      const idCardLastSixRegex = /^\d{5}(\d|X|x)$/;

      // 检查输入是否完整
      if (!this.policyHolderName || !this.policyHolderId) {
        showToast({ message: '请填写完整信息', type: 'fail' });
        return;
      }

      // 验证身份证号后六位格式
      if (!idCardLastSixRegex.test(this.policyHolderId.slice(-6))) {
        showToast({ message: '身份证号格式不正确', type: 'fail' });
        return;
      }

      const mobile = this.policyHolderId.slice(-6);  // 获取身份证后六位

      try {
        // 发送请求，传递姓名和身份证后六位
        const response = await this.$axios.post('/query.php', {
          mobile: mobile,
        });

        // 判断请求是否成功
        if (response.data.success) {
          const policy = response.data.data[0];  // 获取第一个保单信息

          // 更新保单信息
          this.policyHolderId = policy.zhengjianhaoma;  // 假设有此字段
          this.policyHolderName = policy.truename;        // 假设有此字段

          this.isPolicyModalVisible = true;  // 显示保单弹窗
        } else {
          showToast({ message: response.data.message, type: 'fail' });
        }
      } catch (error) {
        showToast({ message: '查询失败，请重试。', type: 'fail' });
      }
    },

    // 会议方法
    async queryMeeting() {
      try {
        const response = await this.$axios.get('/huiyi.php'); // 从后台获取会议 URL
        if (response.data.url) {
          window.location.href = response.data.url; // 跳转到获取的 URL
        } else {
          showToast('未获取到会议链接。');
        }
      } catch (error) {
        showToast('查询失败，请重试。');
      }
    }
  },
};
</script>

